<template lang="">
    <div class="customer-service">
        <div class="content">
            <img src="../assets/images/waiter.png" alt="" class="waiter">
            <div>
                Hi，你好呀，我是{{title}}的产品经理 Walter。<br>
                在使用上遇到什么问题了吗？或是对产品有什么建议？请直接加我的微信跟我反馈吧！<br>
                如若反馈真实有效，还可以获得现金红包哦。
            </div>
            <img class="qr_code" src="../assets/images/qr_code.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    setup() {
        return {
            title: window.document.title
        }
    }

}
</script>
<style lang="scss">
.customer-service {
    position: fixed;
    right: 60px;
    bottom: 60px;
    cursor: pointer;
    width: 70px;
    height: 76px;
    background-image: url('../assets/images/chat.png');
    background-size: 100% 100%;
    z-index: 4;

    &:hover {
        .content {
            display: block;
        }
    }

    .content {
        display: none;
        position: absolute;
        width: 265px;
        padding: 53px 14px 21px 21px;
        right: 0px;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
        border-radius: 10px;
        font-size: 14px;
        color: #999999;
        line-height: 22px;
        .waiter{
            width: 120px;
            position: absolute;
            top: -55px;
            right: 14px;
        }
        .qr_code {
            width: 120px;
            height: 120px;
            margin: 4px 23%;
        }
    }
}
</style>